.img{
    width: 100%;
    
}

/* this is the link or the element that will contain the two icons we want to toggle between */
.toggle-icons {
    display: block;
    position: relative;
  }
  
  /* general styling for both icons - mostly positioning things absolutely to lay over each other */
  .toggle-icons .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: opacity .25s ease-in;
  }
  
  /* set the icon we want to show by default */
  .toggle-icons .icon-default {
    opacity: 1.0;
  }
  
  /* set the icon we want to only show on hover or focus to be hidden by default */
  .toggle-icons .icon-hover {
    opacity: 0;
  }
  
  /* hover states flip the opacity values and thus display our hover-only icon */
  .toggle-icons:hover .icon-default, 
  .toggle-icons:focus .icon-default {
    opacity: 0;
  }
  
  .toggle-icons:hover .icon-hover, 
  .toggle-icons:focus .icon-hover {
    opacity: 1.0;
  }
  
  /* just demo styling, ignore */
  .demo-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: right;
    color: rgb(248, 249, 250);
    background: rgb(33, 37, 40);
  }
  
  .toggle-icons {
    color: inherit;
  }
  
  .toggle-icons:not(:last-child) {
    margin-right: 1rem;
  }
  
  .star-rating {
    color: rgb(255, 224, 102);
  }
  
  .user-feedback-positive {
    color: rgb(192, 235, 118);
  }
  
  .user-feedback-negative {
    color: rgb(240, 62, 62);
  }
  
  .weather-status .icon-default {
    color: rgb(112, 72, 232);
  }
  
  .weather-status .icon-hover {
    color: rgb(255, 146, 43);
  }