
.navbar {
    /* background-color: #333; */
    padding: 10px;
  }
  
  .navbar-item {
    color: white;
    padding: 5px;
    margin: 0 5px;
  }
  
  .navbar-search {
    display: flex; /* Ensure flexbox behavior */
    flex-grow: 1;
    margin-right: 10px;
    margin-left: 65px;
    margin-left: 50px;

  }

  .search-container {
    position: relative;
    width: 350px;
  }
  
  .search-icon {
    position: absolute;
    top: 50%;
    left: 10px; /* Adjust this value as needed */
    transform: translateY(-50%);
    color: #555; /* Adjust the color as needed */
  }
 
  
  .navbar-search input[type="search"] {
    /* background-color: #444; */
    border: none;
    color: white;
    padding: 5px;
    width: 100%; /* Make the input cover full width */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  
  
  .navbar-item button {
    /* background-color: #444; */
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    /* border-radius: 10px; */
    background-color: rgb(66, 213, 66);

   
  }

  #signupButton {
    width: 100%;
    /* background-color: #444; */
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    /* border-radius: 10px; */
    background-color: rgb(66, 213, 66);
    margin-left: 10px;
}

.hr-line {
  width: 100%;
  border: none;
  height: 2px;
  background-color: #4B5563; /* Assuming bg-stone-800 corresponds to #4B5563 */
}
