img {
    max-width: 100%;

}

#fimg {
    border-width: 1px;
    height: 250px;
    text-align: center;
    width: 60%;
}


#email {
    margin-bottom: 0px;
}
.hr-line {
    width: 100%;
    border: none;
    height: 2px;
    background-color: #4B5563; /* Assuming bg-stone-800 corresponds to #4B5563 */
  }
  