
/* footer */
.hover-underline {
    position: relative;
    text-decoration: none; /* Remove default underline */
  }

  .hover-underline::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; /* Adjust this value to change the position of the line */
    width: 100%;
    height: 2px;
    background-color: white; /* Adjust color as needed */
    transition: opacity 0.3s ease; /* Add transition for smooth effect */
    opacity: 0; /* Initially hide the line */
  }

  .hover-underline:hover::after {
    opacity: 1; /* Show the line on hover */
  }


